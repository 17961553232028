/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    h4: "h4",
    code: "code",
    strong: "strong",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Clipboard handles copy, cut and paste between Quill and external applications. A set of defaults exist to provide sane interpretation of pasted content, with the ability for further customization through matchers."), "\n", React.createElement(_components.p, null, "The Clipboard interprets pasted HTML by traversing the corresponding DOM tree in ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Tree_traversal#Post-order"
  }, "post-order"), ", building up a ", React.createElement(_components.a, {
    href: "/docs/delta/"
  }, "Delta"), " representation of all subtrees. At each descendant node, matcher functions are called with the DOM Node and Delta interpretation so far, allowing the matcher to return a modified Delta interpretation."), "\n", React.createElement(_components.p, null, "Familiarity and comfort with ", React.createElement(_components.a, {
    href: "/docs/delta/"
  }, "Deltas"), " is necessary in order to effectively use matchers."), "\n", React.createElement(_components.h2, null, "API"), "\n", React.createElement(_components.h4, null, "addMatcher"), "\n", React.createElement(_components.p, null, "Adds a custom matcher to the Clipboard. Matchers using ", React.createElement(_components.code, null, "nodeType"), " are called first, in the order they were added, followed by matchers using a CSS ", React.createElement(_components.code, null, "selector"), ", also in the order they were added. ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType"
  }, React.createElement(_components.code, null, "nodeType")), " may be ", React.createElement(_components.code, null, "Node.ELEMENT_NODE"), " or ", React.createElement(_components.code, null, "Node.TEXT_NODE"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Methods")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "addMatcher(selector: String, (node: Node, delta: Delta) => Delta)\naddMatcher(nodeType: Number, (node: Node, delta: Delta) => Delta)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Examples")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "quill.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {\n  return new Delta().insert(node.data);\n});\n\n// Interpret a <b> tag as bold\nquill.clipboard.addMatcher('B', function(node, delta) {\n  return delta.compose(new Delta().retain(delta.length(), { bold: true }));\n});\n")), "\n", React.createElement(_components.h3, null, "dangerouslyPasteHTML"), "\n", React.createElement(_components.p, null, "Inserts content represented by HTML snippet into editor at a given index. The snippet is interpreted by Clipboard ", React.createElement(_components.a, {
    href: "#addMatcher"
  }, "matchers"), ", which may not produce the exactly input HTML. If no insertion index is provided, the entire editor contents will be overwritten. The ", React.createElement(_components.a, {
    href: "/docs/api/#events"
  }, "source"), " may be ", React.createElement(_components.code, null, "\"user\""), ", ", React.createElement(_components.code, null, "\"api\""), ", or ", React.createElement(_components.code, null, "\"silent\""), "."), "\n", React.createElement(_components.p, null, "Improper handling of HTML can lead to ", React.createElement(_components.a, {
    href: "https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)"
  }, "cross site scripting (XSS)"), " and failure to sanitize properly is both notoriously error-prone and a leading cause of web vulnerabilities. This method follows React's example and is aptly named to ensure the developer has taken the necessary precautions."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Methods")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "dangerouslyPasteHTML(html: String, source: String = 'api')\ndangerouslyPasteHTML(index: Number, html: String, source: String = 'api')\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Examples")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "quill.setText('Hello!');\n\nquill.clipboard.dangerouslyPasteHTML(5, '&nbsp;<b>World</b>');\n// Editor is now '<p>Hello&nbsp;<strong>World</strong>!</p>';\n")), "\n", React.createElement(_components.h2, null, "Configuration"), "\n", React.createElement(_components.h3, null, "matchers"), "\n", React.createElement(_components.p, null, "An array of matchers can be passed into Clipboard's configuration options. These will be appended after Quill's own default matchers."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const quill = new Quill('#editor', {\n  modules: {\n    clipboard: {\n      matchers: [\n        ['B', customMatcherA],\n        [Node.TEXT_NODE, customMatcherB]\n      ]\n    }\n  }\n});\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
