const items = [
  {
    title: 'Quickstart',
    url: '/docs/quickstart/',
  },
  {
    title: 'Download',
    url: '/docs/download/',
  },
  {
    title: 'Configuration',
    url: '/docs/configuration/',
  },
  {
    title: 'Formats',
    url: '/docs/formats/',
  },
  {
    title: 'API',
    url: '/docs/api/',
    children: [
      {
        title: 'Content',
        url: '/docs/api/#content',
      },
      {
        title: 'Formatting',
        url: '/docs/api/#formatting',
      },
      {
        title: 'Selection',
        url: '/docs/api/#selection',
      },
      {
        title: 'Editor',
        url: '/docs/api/#editor',
      },
      {
        title: 'Events',
        url: '/docs/api/#events',
      },
      {
        title: 'Model',
        url: '/docs/api/#model',
      },
      {
        title: 'Extension',
        url: '/docs/api/#extension',
      },
    ],
  },
  {
    title: 'Delta',
    url: '/docs/delta/',
  },
  {
    title: 'Modules',
    url: '/docs/modules/',
    children: [
      {
        title: 'Toolbar',
        url: '/docs/modules/toolbar/',
      },
      {
        title: 'Keyboard',
        url: '/docs/modules/keyboard/',
      },
      {
        title: 'History',
        url: '/docs/modules/history/',
      },
      {
        title: 'Clipboard',
        url: '/docs/modules/clipboard/',
      },
      {
        title: 'Syntax',
        url: '/docs/modules/syntax/',
      },
    ],
  },
  {
    title: 'Themes',
    url: '/docs/themes/',
  },
];

export default items;
